.dialog-paper {
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  margin-top: 0 !important;
  width: 70% !important;
  height: 65% !important;
  max-width: 1350px !important;
  min-height: 300px !important;
  max-height: 800px !important;
  overflow-y: initial !important;
}

.dialog-paper-no-img {
  background: transparent !important;
  box-shadow: none !important;
  border-radius: 0 !important;
  margin-top: 0 !important;
  width: 70% !important;
  height: 50% !important;
  max-width: 1350px !important;
  min-height: 300px !important;
  max-height: 600px !important;
  overflow-y: initial !important;
}

.dialog-shadow {
  width: 100% !important;
  height: 100% !important;
  margin: auto;
  position: relative;
}

.dialog-arrow {
  display: inline-block;
  position: absolute;
  top: 45%;
  opacity: 0.8;
  border: solid white;
  border-width: 0 2px 2px 0;
  padding: calc(1px + 0.8vw);
  cursor: pointer;
}

.left {
  left: -4%;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.right {
  right: -4%;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.dialog-shape {
  border-radius: 0;
  background: #f2f2f2;
  width: 100%;
  height: 100%;
  margin: auto;
}

.dialog-content {
  width: 100%;
  height: 90%;
  position: relative;
}

.dialog-close {
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 3;
  cursor: pointer;
}

.right-panel {
  width: 30%;
  height: 100%;
  position: absolute;
  display: block;
  right: 0;
}

.right-panel-info {
  position: absolute;
  bottom: 0;
  padding-right: 10%;
}

.right-panel-period {
  display: none;
  font-size: 105%;
  text-transform: uppercase;
}

.right-panel-year {
  display: inline-block;
  margin-top: 10%;
  border-bottom: 4px solid;
  font-family: 'Roboto' !important;
  @apply text-xl;
}

.social-networks-container {
  position: absolute;
  height: 56%;
  width: 100%;
  top: 0;
}

.content-share-text {
  position: absolute;
  bottom: 3%;
  font-size: 70%;
  opacity: 0.7;
}

.social-networks {
  display: inline-block;
  position: absolute;
  bottom: -10%;
}

.social-networks-no-image {
  bottom: -13.2% !important;
}

.social-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
}

.social-network-button:hover {
  opacity: 0.75;
}

.left-panel {
  width: 60%;
  height: 100%;
  position: absolute;
  display: block;
  left: 7%;
}

.left-panel-title-container {
  position: absolute;
  width: 100%;
  top: 0;
}

.panel-with-image {
  height: 56%;
}

.panel-no-image {
  height: 38%;
}

.dialog-header {
  font-family: 'Roboto' !important;
  font-size: 155%;
  font-weight: 600;
  position: absolute;
  bottom: 0;
}

.dialog-img-header {
  color: #555555;
  font-family: 'Roboto' !important;
  font-size: 95%;
  position: absolute;
  bottom: 28%;
}

.dialog-desc {
  width: 100%;
  height: 30%;
  position: absolute;
  top: 56%;
  text-align: justify;
  font-size: 110% !important;
}

.dialog-desc-no-image {
  width: 100%;
  height: 45%;
  position: absolute;
  top: 38%;
  text-align: justify;
  font-size: 110% !important;
}

.dialog-desc-no-image-no-title {
  width: 100%;
  height: 60%;
  position: absolute;
  top: 30%;
  text-align: justify;
  font-size: 110% !important;
}

.dialog-place {
  height: 25%;
  font-size: 100% !important;
}

.dialog-img {
  height: 40%;
  max-width: 65%;
  position: absolute;
  top: -8%;
  left: 7%;
  cursor: pointer;
}

.dialog-spinner {
  height: 30%;
  max-width: 65%;
  position: absolute;
  top: 15%;
  left: 15%;
}

.dialog-img-full-btn {
  position: absolute;
  top: -8%;
  left: 7%;
  z-index: 1;
  cursor: pointer;
}

.category-color {
  width: 98%;
  height: 4px;
  position: absolute;
  bottom: 0;
  display: inline-flex;
}

.content {
  width: 100%;
  height: 100%;
  position: relative;
}

@media (max-width: 750px) {
  .right-panel-year {
    margin-top: 5%;
    font-size: 90%;
  }
}
