.ItemsTable {
    min-width: 600px;
}

.ItemsTable th {
    font-size: 110% !important;
    max-width: 300px;
}

.ItemsTable td {
    font-size: 105% !important;
    padding: 8px !important;
    max-width: 300px;
}

.ItemsTable th {
    font-weight: 600;
}
