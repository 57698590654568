.AdminPage {
  min-width: 100vw;
  min-height: 100vh;
  padding: 5% 0;
  background-size: cover;
  background-position: center
}

.AdminContainer {
  width: 80%;
  margin: auto;
}

.AdminTabs {
  height: 60px !important;
  display: inline-block;
  background: #f2f2f2;
}

.AdminTab {
  /*width: 120px;*/
  height: 60px !important;
  font-size: 1rem !important;
}

.AdminPaper {
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 0 !important;
}

.AddButton {
    width: 160px;
    margin: auto;
    margin-top: 5%;
}


@media all and (max-width: 800px) {
  .AdminContainer {
    width: 92%;
  }
}
