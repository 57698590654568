.period-form {
  width: 70vw;
  max-width: 550px;
}

.period-form-title {
  font-size: 130%;
  font-weight: 500;
  text-align: center;
  margin-top: 10%;
}


.AdminDialogPaper {
  background: #f2f2f2 !important;
  border-radius: 0 !important;
  font-size: 100% !important;
}

.AdminDialogClose {
  position: absolute;
  top: 8px;
  right: 12px;
  cursor: pointer;
}

.PeriodForm {
  padding-top: 5%;
}

.PeriodFormField {
  width: 100%;
  display: inline-block;
  margin-top: 2%;
}

.PeriodFormFieldName {
  width: 20%;
  float: left;
  font-size: 90%;
}

.TextField {
  float: right;
  width: 80%;
  margin-bottom: 3% !important;
}

.YearField {
  width: 50%;
  position: relative;
  display: inline-flex;
}

.EraFields {
  width: 45%;
  display: inline-block;
  position: relative;
  padding-left: 10%;
}

.EraField {
  font-size: 110%;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}

.EraField:hover {
  text-decoration: underline;
}

.SelectedEra {
  color: black;
  text-decoration: underline;
}

.DeniedEra {
  color: #808080;
}

input:disabled {
  color: rgba(0, 0, 0, 0.87) !important;
  margin-top: 4% !important;
}

.HelperText {
  color: red !important;
  margin-left: 0 !important;
  margin-top: 1% !important;
}

.Wrapper {
  width: 105px;
  margin: auto;
  position: relative;
}

.ButtonProgress {
  position: absolute;
  top: 15%;
  left: 40%;
}

.errorText {
  color: red;
  text-align: center;
}

@media all and (max-width: 600px) {
  .YearField {
    width: 80%;
  }

  .EraFields {
    width: 50%;
  }
}
