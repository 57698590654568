.LogInPaper {
  width: 80%;
  max-width: 350px;
  margin: auto;
  margin-top: 20vh;
  padding: 55px 20px 25px 20px;
  border-radius: 0 !important;
}

.LogInTitle {
  font-size: 140%;
  font-weight: 500;
  margin: 0;
  text-align: center;
}

.LogInForm {
  text-align: left;
}

.LogInFormControl {
  width: 100%;
}

.LogInTextField {
  margin-top: 8px !important;
}

.ButtonWrapper {
  width: 80px;
  margin: auto;
}

.NoRadiusButton {
  border-radius: 0 !important;
  color: #f2f2f2 !important;
}

.LogInError {
  color: red;
  margin-bottom: 0;
  text-align: center;
}

.ForgotPassword {
  text-align: center;
}

.ForgotPassword p {
  margin-bottom: 0;
  display: inline-block;
  text-decoration: underline;
}
