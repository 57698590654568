.ResetPage {
  min-width: 100vw;
  min-height: 100vh;
  padding: 5% 0;
  background-size: cover;
}

.ResetError {
  margin-top: 25%;
  font-size: 120%;
}
