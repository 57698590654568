@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
  font-size: 86%;
}

@media (min-width: 1025px) {
  html, body {
    font-size: 92%;
  }
}

@media (min-width: 1921px) {
  html, body {
    font-size: 100%;
  }
}
