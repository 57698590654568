.PeriodsTable {
  min-width: 600px;
}

.PeriodsTable th {
  font-size: 110% !important;
  max-width: 300px;
}

.PeriodsTable td {
  font-size: 105% !important;
  padding: 8px !important;
  max-width: 300px;
}

.PeriodsTable th {
  font-weight: 600;
}

.miniCol {
  width: 50px;
}
